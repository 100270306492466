import React from 'react';

const Checkout = () => {
  return (
    <div>
      <h1>Checkout</h1>
      <p>Placeholder for payment gateway integration</p>
    </div>
  );
};

export default Checkout;
