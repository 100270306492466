import React, { useState } from 'react';
import ProductGrid from '../components/ProductGrid';
import productsData from '../data/products.json';

const Shop = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredProducts, setFilteredProducts] = useState(productsData);
  const [filter, setFilter] = useState({ price: '', category: '' });

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
    filterProducts(event.target.value, filter.price, filter.category);
  };

  const handleFilterChange = (event) => {
    const { name, value } = event.target;
    setFilter({ ...filter, [name]: value });
    filterProducts(searchTerm, name === 'price' ? value : filter.price, name === 'category' ? value : filter.category);
  };

  const filterProducts = (searchTerm, price, category) => {
    let filtered = productsData;

    if (searchTerm) {
      filtered = filtered.filter(product => 
        product.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        product.description.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }

    if (price) {
      if (price === 'low-to-high') {
        filtered = filtered.sort((a, b) => a.price - b.price);
      } else if (price === 'high-to-low') {
        filtered = filtered.sort((a, b) => b.price - a.price);
      }
    }

    if (category) {
      filtered = filtered.filter(product => product.category === category);
    }

    setFilteredProducts(filtered);
  };

  return (
    <div className="shop">
      <h1>Shop All Products</h1>
      <div className="shop__controls">
        <input
          type="text"
          placeholder="Search products..."
          value={searchTerm}
          onChange={handleSearch}
          className="shop__search"
        />
        <div className="shop__filters">
          <select name="price" onChange={handleFilterChange} className="shop__filter">
            <option value="">Sort by Price</option>
            <option value="low-to-high">Low to High</option>
            <option value="high-to-low">High to Low</option>
          </select>
          <select name="category" onChange={handleFilterChange} className="shop__filter">
            <option value="">Filter by Category</option>
            <option value="skincare">Skincare</option>
            <option value="makeup">Makeup</option>
            <option value="bodycare">Bodycare</option>
          </select>
        </div>
      </div>
      <ProductGrid products={filteredProducts} />
    </div>
  );
};

export default Shop;
