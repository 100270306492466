import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FaHome, FaShoppingCart } from 'react-icons/fa';
import { GiToyMallet } from 'react-icons/gi'; // Cute icons from Game Icons
// import './Header.scss'; // Assuming you have SCSS setup for styling

const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <header className="site-header">
      <Link to="/" className="site-logo">
        <h1>Lit Luxe India</h1>
      </Link>
      <nav className={menuOpen ? 'nav-active' : ''}>
        <Link to="/" onClick={toggleMenu}>
          <FaHome size={20} style={{ marginRight: '5px' }} />
          Home
        </Link>
        <Link to="/shop" onClick={toggleMenu}>
          <GiToyMallet size={20} style={{ marginRight: '5px' }} />
          Shop
        </Link>
        <Link to="/cart" onClick={toggleMenu}>
          <FaShoppingCart size={20} style={{ marginRight: '5px' }} />
          Cart
        </Link>
      </nav>
      <div className={`menu-toggle ${menuOpen ? 'active' : ''}`} onClick={toggleMenu}>
        <span></span>
        <span></span>
        <span></span>
      </div>
    </header>
  );
};

export default Header;
