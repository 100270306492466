import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { CartContext } from '../contexts/CartContext';
import Toast from './Toast';

const ProductGrid = ({ products }) => {
    const { addToCart } = React.useContext(CartContext);
    const [showToast, setShowToast] = useState(false);

    const handleAddToCart = (e, product) => {
        e.stopPropagation(); // Prevent any parent click events from triggering
        addToCart(product, 1);
        setShowToast(true); // Show the toast notification
    };


    const closeToast = () => {
        setShowToast(false);
    };
    return (
        <div className="product-grid">
            {products.map(product => (
                <div key={product.id} className="product-card">
                    <Link to={`/product/${product.id}`} className="product-link">
                        <img src={'/assets/' + product.image} alt={product.name} className="product-card__image" />
                    </Link>

                    <h2 className="product-card__name">{product.name}</h2>
                    <p className="product-card__price">₹{product.price}</p>
                    <button className="product-card__add-to-cart" onClick={(e) => handleAddToCart(e, product)}>Add to Cart</button>
                </div>
            ))}

            <Toast
                message="Item successfully added to cart!"
                show={showToast}
                onClose={closeToast}
            />
        </div>
    );
};

export default ProductGrid;
