import React, { useContext, useState } from 'react';
import { useParams } from 'react-router-dom';
import products from '../data/products.json';
import { CartContext } from '../contexts/CartContext';

const ProductDetail = () => {
  const { id } = useParams();
  const product = products.find(p => p.id === parseInt(id));
  const { addToCart } = useContext(CartContext);

  const [selectedVariant, setSelectedVariant] = useState(
    product.variants.length > 0 ? product.variants[0] : product
  );
  const [quantity, setQuantity] = useState(1);
  const [showRelatedProducts, setShowRelatedProducts] = useState(false);

  const handleVariantChange = (variantId) => {
    const variant = product.variants.find(v => v.id === variantId);
    setSelectedVariant(variant);
  };

  const handleAddToCart = () => {
    addToCart(selectedVariant, quantity);
    if (product.relatedProducts && product.relatedProducts.length > 0) {
      setShowRelatedProducts(true);
    }
  };

  const handleAddRelatedProduct = (relatedProduct) => {
    addToCart(relatedProduct, 1, relatedProduct.discountedPrice); // Default to 1 quantity for related products
    setShowRelatedProducts(false);
  };

  return (
    <div className="product-detail">
      <div className="product-detail__container">
        <div className="product-detail__image">
          <img src={'/assets/' + selectedVariant.image} alt={selectedVariant.name} />
        </div>
        <div className="product-detail__info">
          <h1 className="product-detail__name">{product.name}</h1>
          <p className="product-detail__description">{selectedVariant.description || product.description}</p>
          <p className="product-detail__price">₹{selectedVariant.price}</p>

          {product.variants.length > 0 && (
            <div className="product-detail__variants">
              <label>Select Variant:</label>
              <select onChange={(e) => handleVariantChange(e.target.value)} value={selectedVariant.id}>
                {product.variants.map(variant => (
                  <option key={variant.id} value={variant.id}>
                    {variant.name} - ₹{variant.price}
                  </option>
                ))}
              </select>
            </div>
          )}

          <div className="product-detail__quantity">
            <label>Quantity:</label>
            <input
              type="number"
              value={quantity}
              min="1"
              onChange={(e) => setQuantity(parseInt(e.target.value))}
            />
          </div>

          <button className="product-detail__add-to-cart" onClick={handleAddToCart}>
            Add to Cart
          </button>

          {showRelatedProducts && (
            <div className="related-products-modal">
              <h2>Special Offer!</h2>
              <p>Buy these products together at a discount:</p>
              <ul>
                {product.relatedProducts.map((relatedProduct) => (
                  <li key={relatedProduct.id}>
                    <img src={'/assets/' + relatedProduct.image} alt={relatedProduct.name} />
                    <p>{relatedProduct.name}</p>
                    <p>Discounted Price: ₹{relatedProduct.discountedPrice}</p>
                    <button onClick={() => handleAddRelatedProduct(relatedProduct)}>
                      Add {relatedProduct.name} to Cart
                    </button>
                  </li>
                ))}
              </ul>
              <button onClick={() => setShowRelatedProducts(false)}>No Thanks</button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ProductDetail;
