import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { CartContext } from '../contexts/CartContext';
import Toast from './Toast';

const ProductCard = ({ product }) => {
  const { addToCart } = React.useContext(CartContext);
  const navigate = useNavigate();
  const [showToast, setShowToast] = useState(false);

  const handleAddToCart = (e) => {
    e.stopPropagation(); // Prevent any parent click events from triggering
    addToCart(product, 1);
    setShowToast(true); // Show the toast notification
  };

  const handleBuyNow = (e) => {
    e.stopPropagation(); // Prevent any parent click events from triggering
    addToCart(product, 1);
    navigate('/checkout');
  };

  const closeToast = () => {
    setShowToast(false);
  };

  return (
    <div className="product-card" style={{backgroundColor: product.accent}}>
      <Link to={`/product/${product.id}`} className="product-link">
        <img src={`/assets/${product.titleImage}`} alt={product.name} />
        <div className="product-image">
          <p>{product.description}</p>
          <img src={`/assets/${product.image}`} alt={product.name} />
        </div>
        <p className="price">₹{product.price}</p>
      </Link>
      <div className="product-actions">
        <button onClick={handleAddToCart} className="atc">Add to Cart</button>
        <button onClick={handleBuyNow}>Buy Now</button>
      </div>

      {/* Toast notification */}
      <Toast
        message="Item successfully added to cart!"
        show={showToast}
        onClose={closeToast}
      />
    </div>
  );
};

export default ProductCard;
