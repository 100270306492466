import React, { useContext } from 'react';
import { CartContext } from '../contexts/CartContext';
import { Link } from 'react-router-dom';
// import './Cart.scss'; // We'll create this stylesheet

const Cart = () => {
  const { cart, updateCartQuantity, removeFromCart, getTotalCartValue } = useContext(CartContext);

  const handleQuantityChange = (productId, quantity) => {
    updateCartQuantity(productId, parseInt(quantity));
  };

  return (
    <div className="cart">
      <h1>Your Cart</h1>
      {cart.length === 0 ? (
        <p>Your cart is empty</p>
      ) : (
        <div className="cart__content">
          <ul className="cart__items">
            {cart.map(item => (
              <li key={item.id} className="cart__item">
                <img src={`/assets/${item.image}`} alt={item.name} className="cart__item-image" />
                <div className="cart__item-details">
                  <h2 className="cart__item-name">{item.name}</h2>
                  <p className="cart__item-price">Price: ₹{item.price}</p>
                  <div className="cart__item-quantity">
                    <label htmlFor={`quantity-${item.id}`}>Quantity: </label>
                    <input
                      type="number"
                      id={`quantity-${item.id}`}
                      value={item.quantity}
                      min="1"
                      onChange={(e) => handleQuantityChange(item.id, e.target.value)}
                    />
                  </div>
                  <p className="cart__item-total">Total: ₹{item.price * item.quantity}</p>
                  <button className="cart__item-remove" onClick={() => removeFromCart(item.id)}>Remove</button>
                </div>
              </li>
            ))}
          </ul>
          <div className="cart__summary">
            <h2>Cart Summary</h2>
            <p>Total: ₹{getTotalCartValue()}</p>
            <Link to="/checkout">
              <button className="cart__checkout-button">Proceed to Checkout</button>
            </Link>
          </div>
        </div>
      )}
    </div>
  );
};

export default Cart;
